/* eslint-disable @typescript-eslint/member-ordering */
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PaymentModalComponent } from 'src/app/components/payment-modal/payment-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  winWidth = window.innerWidth;
  displayNav = false;

  @ViewChild('navdiv') navDiv: ElementRef;
  constructor(
    private renderer: Renderer2,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {}
  ngAfterViewInit(): void {
    this.toggleNav();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.winWidth = window.innerWidth;
  }

  toggleNav() {
    if (this.winWidth <= 991 && this.displayNav) {
      this.displayNav = false;
    } else {
      this.displayNav = true;
    }

    if (this.displayNav) {
      this.renderer.setStyle(this.navDiv.nativeElement, 'display', 'none');
    } else if (!this.displayNav) {
      this.renderer.setStyle(this.navDiv.nativeElement, 'display', 'block');
    }
  }
}
