/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SERVER_URL } from 'src/app/constant/constant';
import { CmnServiceService } from 'src/app/SERVICE/cmnService/cmn-service.service';

@Component({
  selector: 'app-referral-detail',
  templateUrl: './referral-detail.component.html',
  styleUrls: ['./referral-detail.component.scss'],
})
export class ReferralDetailComponent implements OnInit {
  @Input() refData: any;
  rejectReason:string = "";
  constructor(
    public modalCtrl: ModalController,
    private cmnService: CmnServiceService,
    private http: HttpClient
  ) {}

  ngOnInit() {}
  onViewPDFClick() {
    window.open(this.refData?.filepath, '_black');
  }

  onAttachmentClick(a) {
    window.open(a, '_black');
  }

  changeReason(event){
    this.rejectReason = event;
  }
  onActionPerformed(i, action) {

    if (action == '2' && this.rejectReason.length < 1){
      return this.cmnService.showWarning("Please Enter Reason.")
    }
    
    const data = {
      referral_id: i?.id,
      action,
    };

    if (action == '2'){
      data['not_accept_reason'] = this.rejectReason;
    }

    const referral_data = {
      referral_id:i?.id,
      doctor_id:i?.referredDoctorId,
      receiver_type:"p-2-d",
      creator:"doctor",
      last_message:"",
      topic_id:"",
      patient_id:"",
      practice_id:"",
      to_practice_id:""
    }

    const actionName =
      i?.refType === 'internal'
        ? 'v2/action_on_referral'
        : 'v2/action_on_external_referral';

    this.cmnService.showLoader();
    this.http
      .post(SERVER_URL + actionName, data, {
        responseType: 'text',
      })
      .subscribe(
        (res: any) => {
          this.http.post(SERVER_URL + 'v2/create_chat_room_by_referral', referral_data).subscribe((response:any)=>{
            this.cmnService.hideLoader();
            this.modalCtrl.dismiss('updated');
          }, (error:any)=>{
            this.cmnService.hideLoader();
          })
        },
        (err) => {
          this.cmnService.hideLoader();
        }
      );
  }
}
